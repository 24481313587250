"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, is,setAttribute, removeAttribute} from "@elements/dom-utils";
import {serializeArray} from "./helpers/dom-utils-extended";
import * as formValidation from '@elements/form-validation';

export function init() {
    onFind('.js-conditional-form', function (form) {
        let formParts = findAllIn('.js-conditional-form__part', form);

        formParts.map((part) => {
            if(part.hidden){
                let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',part);
                optionalRequiredFields.map((element) => {
                        element.required = false;
                });
                let optionalDisabledFields = findAllIn('.js-conditional-form__optional-disabled', part);

                optionalDisabledFields.map((element) => {
                    element.disabled = true;
                });
            }
        });

        on('change', function(event) {
            formParts.map(setAttribute('hidden', 'hidden'));

            let optionalDisabledFields = findAllIn('.js-conditional-form__optional-disabled',form);
            optionalDisabledFields.map((element) => {
                    element.disabled = true;
            });

            let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',form);
            optionalRequiredFields.map((element) => {
                element.required = false;

            });


            let formData = serializeArray(form);

            formData
                .filter(({name, value}) => !!value)
                .forEach(({name, value}) => {

                    let currentParts = formParts.filter(is(`[data-name="${name}"]`));
                    currentParts.filter(is(`[data-value="${value}"]`)).map(function(currentPart) {
                        removeAttribute('hidden', currentPart);

                        let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',currentPart);
                        optionalRequiredFields.required = true;

                        let optionalDisabledFields = findAllIn('.js-conditional-form__optional-disabled',currentPart);
                        optionalDisabledFields.disabled = false;

                        if (optionalDisabledFields.length > 0) {
                        optionalDisabledFields.map((element) => {
                            element.disabled= false;
                            formValidation.getApi(form).then((formApi) => {
                                let formInstance = formApi.getFormInstance();

                                formInstance.addField(element.name, {});
                            });
                        });
                        }

                        optionalRequiredFields.map((element) => {
                            element.required = true;
                            formValidation.getApi(form).then((formApi) => {
                                let formInstance = formApi.getFormInstance();
                                formInstance.addField(element.name, {});
                            });
                        });
                    });
                });
        }, form);
    });
}